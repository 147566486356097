.homepage-container {
    background-color: black;
}

@media only screen and (max-width: 768px) {

    .homepage-container {
        background-color: black;
    }  

}