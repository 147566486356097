.about-main-container {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
}

.nav-bar-gap {
    height: 60px;
}

.about-container {
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
    margin: auto;
    padding-top: 100px;
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    
}



.about-background-image-container {
    position: fixed;
    width: 100%;
    overflow: hidden;
    height: 100vh;
    top: 50%;
    transform: translate(0%,-50%);
    z-index: 0;
    
}
.about-background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    overflow:auto;
    
    
    
}

.rock-salt-regular {
    font-family: "Rock Salt", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: clamp(118px, 60vw, 224px);
    color: rgb(62, 147, 153);
    text-shadow: 10px 30px black;
  }
.about-title-container {
    /* border: 1px solid red; */
    padding: 10px 20px;
}

.about-title-text {
    font-size: clamp(36px, 5vw, 48px);
    color: white;
    text-align: center;
    
}



.about-origin-content-image{
    width: 40%;
}


.about-origin-content-text {
    font-size: clamp(18px, 2vw, 24px);
    width: 50%;
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.glass-effect {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    /* min-height: 100%; */
    width: 80%;
    border-radius: 1rem;
    background: rgb(255, 255, 255, 0.3);
    padding: 1.5rem;
    color: #fff;

}

.about-origin-content-section {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center; 
}

.about-origin-container {
    width: 80%;
    margin: auto;
}

.about-mission-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: black; */
}

.about-content-section-label {
    font-size: clamp(48px, 5vw, 68px);
    text-align: center;
}

.about-team-container {
    width: 100%;
    background-color: black;

}

.about-content-section-text {
    font-size: clamp(22px, 2vw, 28px);
}


.about-team-section-container {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: start;    
}

.about-team-profile-card {
    width: 400px;
    /* border: 1px solid red; */
    padding: 10px ;
    background-color: gray;
    border-radius: 25px;
    margin: 10px;
}

.profile-image-container {
    width: 50%;
    margin: auto;
    /* aspect-ratio: 1; */
    border-radius: 50%;
    background-color: black;
    
    
}

.profile-image {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
}

.profile-education-item {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0px;
}

.profile-education-item-content-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
    /* margin: auto;   */
}

.profile-education-item-content {
    width: 80%;
}

.profile-education-item-logo {
    height: 90%;
    width: 40px;
    /* height:40px; */
    /* object-fit: contain; */
}


.profile-name {
    font-size: clamp(22px, 2vw,28px);
    width: 100%;
    text-align: center;
}

.profile-title {
    font-size: clamp(18px, 2vw,24px);
    width: 100%;
    text-align: center;    
}

.profile-education-container {
    /* padding: 20px 0px;
    width: 90%; */
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}

.profile-education-label {
    font-size: clamp(16px, 2vw,18px);
    /* width: 100%; */
    text-align: start;  
}
.profile-education-content {
    font-size: clamp(12px, 2vw,16px);
    color: rgb(218, 218, 218);
}

.card-divider {
    height: 1px;
    width: 30%;
    border-radius: 25%;
    background-color: rgb(58, 58, 58);
}

.mission-content-container {
    width: 60%;
    height: 100px;
    margin: 10px;
    /* background-color: rgb(104, 104, 104); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: clamp(22px, 5vw, 28px);
    text-align: center;
    font-style: italic;
    font-weight: 100;
}


@media only screen and (max-width: 768px) {
    .about-container {
        width: 100%;
        height: 100%;
        margin: auto;
        padding-top: 20px;
        color: white
        
        
    }

    .about-origin-container {
        width: 100%;
        margin: auto;
    }
    .about-origin-content-image{
        display: none;
    }    

    .about-origin-content-text {
        font-size: clamp(18px, 2vw, 24px);
        width: 100%;
        padding: 10px;
    }    

    .about-content-section-label {
        font-size: clamp(48px, 5vw, 68px);
        text-align: center;
    }

    

    .about-origin-content-section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; 
     }    


     .about-team-section-container {
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;    
    }
    .about-team-profile-card {
        width: 300px;
        padding: 10px ;
        background-color: gray;
        
    }

    .profile-education-item-logo {
        height: 90%;
        width: 30px
        /* object-fit: contain; */
    }
    
}
