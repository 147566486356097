.privacy-policy-container {
    display: flex;
    flex-direction: column;
    /* justify-content: ; */
    align-items: center;
    background-color: white;
}

.privacy-policy-sub-container {
    
    width: 50%;
    margin: auto;

}
.nav-bar-gap {
    height: 60px;
}

.privacy-header {
    padding: 0px;
}

.privacy-header-text {
    font-weight: 300;
    font-size: 44px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
    .privacy-policy-sub-container {
    
        width: 90%;
        margin: auto;
    
    }

    .privacy-header-text {
        font-weight: 300;
        font-size: 22px;
        margin: 0;
        text-align: center;
        margin-bottom: 20px;
    }    
}