
.carousel-container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    overflow-x: hidden;
}
.carousel-button-container {
    position: absolute;
    width: 90%;
    /* height: 50%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* bottom/: -5%; */
    /* transform: translate(0%,0%); */
}
.carousel-button {
    background-color: rgba(139, 137, 137, 0.808);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: none;
}

.carousel-images-container {
    width:100%;
    min-height: 600px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

}

.carousel-side-image-container {
    /* height:500px; */
    position: absolute;
    
}

.carousel-main-image-container {
    position: absolute;
    
}

.carousel-image {
    height: 500px;
    
}
@media only screen and (max-width: 768px) {
    .decoration-card {
        display: none;
    }
    .side-image {
        display: none;
    }

}