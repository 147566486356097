.portfolio-card {
    width: 250px;
    height: 330px;
    /* background-color: beige; */
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* box-shadow: 4px 4px gray; */
    background: rgba(236, 233, 233, 0.911);
    -webkit-backdrop-filter: blur(18px);
    backdrop-filter: blur(18px);
    border: 1px solid rgba(255, 255, 255, 0.055);    
    z-index: 1;

    

}

.portfolio-card-image {

    width: 100%;
    aspect-ratio: 1;
    background-color: orange;
    border-radius: 15px 15px 0px 0px;
}

.portfolio-card-label {
    /* height: 30%; */
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 25%;
    border-radius: 0px 0px 15px 15px;
}

.portfolio-card-label-text {

    font-size: clamp(28px, 2vw, 36px);
    color: black;
}