.navbar-container {
    width: 100%;
    height: 60px;
    /* position: fixed; */
    z-index:1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 1);
}


.navbar-elem {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:80%;
    height: 90%;
    /* border-top: 0.5px solid white;
    border-bottom: 0.5px solid white; */

}
.navbar-logo-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.navbar-logo {
    /* color: rgba(226, 226, 226, 0.747); */
    font-size: clamp(1.5rem, 5.5vw, 3em); 
}
.navbar-items-container {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    list-style-type: none;
    /* color: inherit; */
    text-decoration: none;
}

.navbar-item-link {
    text-decoration: none;
    color: rgba(221, 217, 217, 0.747);
}

.navbar-item-container {
    height: 90%;
    cursor: pointer;
    color: rgba(221, 217, 217, 0.747);
    font-size: clamp(1rem, 2.5vw, 1.8rem);
}

.nav-menu-item-container {
    position: absolute;
    width: 200px;
    /* background-color: #000000; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 1%;
}

.nav-menu-item {
    margin-top: 5%;
    margin-bottom:5%;
    margin-left:5% ;
    margin-right:5% ;
    width: 100%;
    text-align: left;
    color: rgba(221, 217, 217, 0.747);
    height: 100%;
    font-size: clamp(0.8rem, 2.5vw, 1.2rem);
}



.mobile-navbar-container {
    background-color: rgba(0, 0, 0, 1);
    width: 100%;
    height: 50px;
    position: fixed;
    z-index:1100
}

.mobile-navbar-logo {
    position: absolute;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(221, 217, 217, 0.747);
}

.mobile-navbar-elem {
    background-color: rgba(0, 0, 0, 0.89);
    width: 300px;
    height: 100vh;
    padding-top: 100px;
    color: rgba(221, 217, 217, 0.747);
   
}

.mobile-navbar-item-container {
    width: 70%;
    margin: auto;
    text-align: left;
    
}
.mobile-nav-menu-elem {
    font-size: clamp(1.5rem, 4.5vh, 2rem);
}
.mobile-nav-menu-item {
    font-size: clamp(1rem, 4.5vh, 1.5rem);
    padding-left: 10%;
}


.filter-custom {
    filter: invert(95%) sepia(0%) saturate(1046%) hue-rotate(19deg) brightness(97%) contrast(87%);
}
