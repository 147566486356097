.games-page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}



.games-page-head-container {
    background-color: black;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.game-page-head-image {
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit:cover;
}

.games-page-head-label {
    font-size: 124px;
    color: white;
    z-index: 1;   
}

.games-page-content-section {
    height: 100vh;
    background-color: black;
    padding: 20px 0px;
}

.coming-soon-label {
    width: 90%;
    font-size: 44px;
    color: white;
    font-weight: 100;
    margin: auto;
    padding: 20px 0px;
}

.coming-soon-container {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.all-games-label {
    width: 90%;
    font-size: 44px;
    color: white;
    font-weight: 100;
    margin: auto;
    padding: 20px 0px;
}

.all-games-container {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}



@media only screen and (max-width: 768px) {
    .games-page-head-label {
        font-size: 44px;
        color: white;
        z-index: 1;   
    }

    .coming-soon-label {
        width: 90%;
        font-size: 36px;
        color: white;
        font-weight: 100;
        margin: auto;
        padding: 20px 0px;
        text-align: center;
    }

    .coming-soon-container {
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    

    .all-games-label {
        width: 90%;
        font-size: 36px;
        color: white;
        font-weight: 100;
        margin: auto;
        padding: 20px 0px;
        text-align: center;
    }

    .all-games-container {
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
        
    
    
}