.footer-container {
    width: 100%;
    /* background-color: black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    padding-top: 100px;

}

.footer-logo-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px
    /* position: relative; */
}


.footer-logo-decoration-strip-top {
    /* position: absolute;  */
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.footer-logo-decoration-strip-bottom {
    /* position: absolute;  */
    background-color: grey;
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    
}


.footer-logo-container {
    /* transform: translate(0px,-50%); */
    border-radius: 50%;
    background-color: grey;
    position: absolute;
    /* height: 50%;
    width: 100%; */
}

.footer-logo {
    width: 100px ;
    height: 100px ;
    padding: 10px;
    
    
}


.footer-content-container {
    background-color: grey;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;    
}

.footer-title-container {
    /* width: 60%; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer-title {
    font-size: clamp(14px, 5vw, 22px);
    color: black;
}

.footer-divider {
    height: 2px;
    background-color:black ;
    width: 80%;
}

.footer-social-media-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;

}

.footer-social-media-icon {
    padding: 10px;
    width: 22px;
    height: 22px;
}


.footer-pages-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer-pages-item {
    padding: 10px 5px;
}

.footer-item-link {
    text-decoration: none;    
    color: black
}

@media only screen and (max-width: 768px) {
    .footer-container {
        width: 100%;
        /* background-color: black; */
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        align-self: flex-end;
        padding-top: 100px;
    
    }
    
}