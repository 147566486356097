.landing-container {
    background-color: black;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.landing-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    /* background-color: orange; */
    /* font-family:  */
}

.landing-title {
    font-size: clamp(36px, 5vw, 106px);
    font-weight: 600;
}

.landing-sub-title {
    font-size: clamp(22px, 5vw, 44px);
    font-weight: 100;
}


@media only screen and (max-width: 768px) {

    .landing-container {
        background-color: black;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .landing-title-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        padding: 0px 20px;
        /* font-family:  */
    }    

}