
.about-us-container {
    width: 100%;
    /* height: 50vh; */
    background-color: black;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center
}

.about-us-label-container {
    width: 80%;
    padding: 20px 0px;

}
.about-us-label {
    font-size: clamp(26px, 2vw, 44px);
    color: white;
}


.about-us-content-container {
    width: 80%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid aqua; */
    padding: 20px 10px;
    /* height: 70%; */
}

.about-us-content-text-container {
    
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    
}

.about-us-content-text {
    color: white;
    font-size: clamp(18px, 2vw, 36px);
    font-weight: 100;
}

.learn-more-button {
    padding: 10px 30px;
    background-color: transparent;
    border: 1px solid white;
    font-size: clamp(18px, 2vw, 24px);
    color: white;
    border-radius: 10px;
}


.about-us-content-image-container {
    width: 40%;
    
}
.about-us-content-image {
    object-fit:cover;
    width: 100%;
    height: 100%;
}




@media only screen and (max-width: 768px) {
    .about-us-label-container {
        width: 80%;
        padding: 20px 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* background-color: red; */
    
    }

    .about-us-content-container {
        width: 90%;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 00px 10px;
        /* border: 1px solid aqua; */
        /* padding: 20px 10px; */
        /* height: 70%; */
    }    


    .about-us-content-text-container {
    
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* padding: 10px 10px; */
        
    }
    

    .about-us-content-image-container {
        display: none;
    }    

}