.portfolio-container {

    width: 100%;
    /* height: 100vh; */
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;

}

.portfolio-label-container {
    width: 80%;
    padding: 20px 10px;
    margin: 0 auto;
}

.portfolio-label-text {
    color: white;
    font-size: clamp(22px, 2vw, 44px);
}


.portfolio-card-container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: start;
}



@media only screen and (max-width: 768px) {
    /* .portfolio-container {

        width: 100%;
        background-color: blue;
    } */
}